@font-face {
  font-family: 'Amalfi-Coast';
  src: url('Amalfi-Coast.ttf');
  font-weight: normal;
}

/* general */
* {
  font-family: 'Helvetica Neue';
}

*:focus {
  outline: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Amalfi-Coast';
}

h1,h2,h3,h4,h5,h6, p {
  color: #fff;
}

h1 {
  margin: 0;
  margin-bottom: 0.1em;
  font-size: 70px;
  line-height: 1.2em;
}

p {
  line-height: 1.5em;
  margin: 0 0 10px 0;
  font-size: 15px;
}

a {
  color: #fff;
  text-decoration-thickness: 2px;
}



#pre-loader {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.4s ease;
  pointer-events: none;
}

#pre-loader.stop {
  opacity: 0;
}

.main-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

/* slider */
.tns-outer, .tns-inner, .main-slider, .tns-item, .image-container {
  width: 100%;
  height: 100%;
}

.image-container > img.bgr-like {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.slider-controls {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.slider-control {
  position: absolute;
  background: #fff;
  padding: 5px;
  cursor: pointer;
}

.slider-control:first-child {
  left: 10px;
}

.slider-control:last-child {
  right: 10px;
}

@media (max-width: 1000px) {
  
}

/* box */
.floating-box {
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 70px 30px 20px 30px;
  max-width: 850px;
  backdrop-filter: blur(25px);
}

/* slightly transparent fallback */
.floating-box {
  background-color: rgba(255, 255, 255, .8);
}

.floating-box * {
  color: #000;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .floating-box {
    background-color: rgba(255, 255, 255, .0);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
  }

  .floating-box * {
    color: #fff;
  }
}

.headline {
  position: absolute;
  right: -280px;
  top: -10px;
}

.arrow {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1200px) {
  p, h1 {
    color: #000;
  }

  h1 {
    font-size: 32px;
  }

  .main-wrapper {
    height: 100%;
  }

  .tns-outer, .tns-inner, .main-slider, .tns-item, .image-container {
    height: 80vh;
  }

  .floating-box {
    position: static;
    backdrop-filter: none;
    box-sizing: border-box;
    padding: 30px 25px;
  }

  .floating-box * {
    color: #000;
  }

  .headline {
    position: static;
  }
}